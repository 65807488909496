.menucontextual {
    animation: animacionMenu 0.15s linear;
}

@keyframes animacionMenu {
    0% {
        translate: -50% -50%;
        scale: 0;
    }

    100% {
        translate: 0;
        scale: 100%;
    }
}