.itemBarra {
    width: 50px;
    text-align: center;
    margin-left: 4px;
    font-weight: bold;
}

.itemBarra > div {
    border-radius: 4px;
    padding: 10px 0;
}

.itemBarra > div:hover, .itemBarra > div.active {
    background-color: #212529;
}