.fondomodalerror {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
}

.contenidomodalerror {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

.sombramodalerror {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25)
}
