$fuente-sistema: 'Figtree';
$fuente-logo: 'Sora';

@font-face {
    font-family: $fuente-sistema;
    src: url('../ttf/#{$fuente-sistema}/#{$fuente-sistema}-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: $fuente-sistema;
    src: url('../ttf/#{$fuente-sistema}/#{$fuente-sistema}-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: $fuente-logo;
    src: url('../ttf/#{$fuente-logo}/#{$fuente-logo}-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: $fuente-logo;
    src: url('../ttf/#{$fuente-logo}/#{$fuente-logo}-Bold.ttf');
    font-weight: bold;
}

$font-family-sans-serif: $fuente-sistema, sans-serif;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #aea79f !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #333 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #3e009a !default;
$purple: #772953 !default;
$pink: #e83e8c !default;
$red: #df382c !default;
$orange: #e95420 !default;
$yellow: #efb73e !default;
$green: #38b44a !default;
$teal: #20c997 !default;
$cyan: #41bcd0 !default;

$primary: $red !default;
$secondary: $gray-500 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-900 !default;

$min-contrast-ratio: 1.8 !default;

$body-color: $gray-800 !default;

$table-dark-bg: $dark !default;
$table-dark-border-color: darken($dark, 5%) !default;

$input-border-color: $gray-400;
$input-group-addon-bg: $gray-100;

$border-radius: 0.25rem;

@import '../../node_modules/bootstrap/scss/bootstrap.scss';

body {
    user-select: none;
    background-color: $gray-200;
}

.App {
    min-height: 100vh
}

.btn-light {
    background-color: $white;
}

.botoncitoTitulo {
    cursor: pointer;
    border-radius: 4px;
}

.botoncitoTitulo:hover {
    background-color: $gray-900
}

.trhover:hover {
    cursor: pointer;
    background-color: mix($white, $secondary, 75%);
}

.trhover:active {
    background-color: mix($white, $secondary, 50%);
}

.prohibido {
    cursor: not-allowed;
}

.cardhover:hover {
    background-color: $gray-200;
    cursor: pointer;
}

.cardhover:active {
    background-color: $gray-300;
}

.renglonhover:hover {
    background-color: mix($white, $secondary, 75%);
    cursor: pointer;
}

.renglonhover:active {
    background-color: mix($white, $secondary, 60%);
}

.renglonSel {
    background-color: mix($white, $info, 65%)
}

.trhover.renglonSel:hover {
    background-color: mix($white, $info, 45%)
}

.trhover.renglonSel:active {
    background-color: mix($white, $info, 25%)
}

.fuentetitulo {
    font-family: $fuente-logo, sans-serif;
}

.cursorpointer {
    cursor: pointer
}

.headerTabla {
    border-bottom: 2px $primary solid;
}

.footerTabla {
    border-top: 2px $primary solid;
}

.modoOscuro.App {
    background-color: $black;
    color: $light;
}

.modoOscuro {
    .bg-white, .bg-light {
        background-color: $black !important;
    }

    .bg-secondary {
        background-color: $gray-700 !important;
    }

    .btn-light {
        background-color: $dark;
        border-color: $gray-700;
        color: $light;
    }

    .btn-light:hover {
        background-color: $gray-700;
    }

    .btn-secondary {
        background-color: $gray-700;
        border-color: $gray-700;
        color: $light;
    }

    .btn-secondary:hover {
        background-color: $gray-800;
    }

    .card {
        background-color: $dark;
    }

    .cardhover:hover {
        background-color: $gray-800;
        cursor: pointer;
    }

    .cardhover:active {
        background-color: $gray-700;
    }

    .badge.bg-light {
        background-color: $black;
    }

    .form-control, .form-select {
        background-color: $black;
        color: $light;
        border-color: $gray-700;
    }

    .input-group-text {
        background-color: $gray-900;
        color: $light;
        border-color: $gray-700;
    }

    .table {
        background-color: $black;
    }

    .border, thead, tbody, tfoot, tr, td, th {
        border-color: $gray-700 !important;
    }

    .headerTabla {
        border-bottom: 2px $primary solid !important;
    }

    .footerTabla {
        border-top: 2px $primary solid !important;
    }

    th {
        color: $light;
    }

    tr {
        color: $light;
    }

    .trhover:hover {
        cursor: pointer;
        background-color: mix($black, $secondary, 75%);
    }

    .trhover:active {
        background-color: mix($black, $secondary, 50%);
    }

    .dropdown-menu {
        background-color: $gray-900;
        border-color: $gray-700;
    }

    .dropdown-divider {
        border-color: $gray-700;
    }

    .dropdown-item {
        color: $light;
    }

    .dropdown-item:hover {
        background-color: $gray-700;
    }

    .dropdown-item:active {
        background-color: $primary;
    }

    .renglonSel {
        background-color: mix($black, $info, 55%)
    }

    .trhover.renglonSel:hover {
        background-color: mix($black, $info, 40%)
    }

    .trhover.renglonSel:active {
        background-color: mix($black, $info, 25%)
    }

}