.fondomodal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    /*animation: fondomanim linear 0.2s;*/
    background-color: rgba(96, 96, 96, 0.5);
    backdrop-filter: blur(5px) grayscale(50%);
}

.alineacionmodal {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes fondomanim {
    0% {
        background-color: rgba(96, 96, 96, 0);
        backdrop-filter: blur(0px) grayscale(0%);
    }
    100% {
        background-color: rgba(96, 96, 96, 0.5);
        backdrop-filter: blur(5px) grayscale(50%);
    }
}

.contenidomodal {
    position: fixed;
    /*animation: modalanim ease-out 0.2s;*/
    opacity: 1;
    max-height: 100vh;
    overflow-y: auto;
}

.modal-full {
    width: 90vw;
}

.modal-grande {
    width: 75vw;
}

.modal-mediano {
    width: 55vw;
}

@media (max-width: 1200px) {
    .modal-grande {
        width: 85vw;
    }

    .modal-mediano {
        width: 75vw;
    }

    .modal-full {
        width: 95vw;
    }
}

@media (max-width: 992px) {
    .modal-grande {
        width: 95vw;
    }

    .modal-mediano {
        width: 95vw;
    }
}

@keyframes modalanim {
    0% {
        transform: scale(75%);
        opacity: 0;
    }
    100% {
        transform: scale(100%);
        opacity: 1;
    }
}

.sombramodal {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25)
}
