.linklogin {
    display: inline-block;
    color: #dee2e6;
    padding: 2px 8px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 4px;
}

.linklogin:hover {
    color: #ffffff;
    background-color: #333333;
}