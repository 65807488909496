.pantallacargando {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    animation: anim_pantallacargando 0.5s linear forwards;
}

@keyframes anim_pantallacargando {
    0% {

    }
    100% {
        backdrop-filter: blur(2px) grayscale(50%);
    }
}

.iconocargando {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}
